/* add css module styles here (optional) */

.vpfr_container {
  position: relative;
  border-radius: 6px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  overflow: hidden;
  outline: none;
}

.vpfr_container:-webkit-full-screen {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.vpfr_container:fullscreen,
.vpfr_container:-webkit-fullscreen {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.vpfr_container:-webkit-fullscreen .vpfr_video {
  width: 100%;
}

.vpfr_video_wrapper {
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;
  position: relative;
  margin: auto;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.3);
}

.vpfr_video {
  display: block;
  pointer-events: none;
  margin: 0 auto;
}

.vpfr_video::-webkit-media-controls,
.vpfr_video::-webkit-media-controls-enclosure {
  display: none !important;
}

.vpfr_player_controls {
  position: absolute;
  z-index: 96;
  width: calc(100% - 20px);
  bottom: 0;
  transition: all 0.3s ease;
  padding: 10px;
  padding-top: 30px;
  opacity: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}

.vpfr_play_pause_on_click {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 95;
  transition: all 0.3s ease;
}

.vpfr_paused_player .vpfr_play_pause_on_click {
  background: rgba(0, 0, 0, 0.4);
}

.vpfr_player_controls_shadow {
  position: absolute;
  width: calc(100% + 100px);
  height: 140px;
  left: -50px;
  bottom: 0;
  box-shadow: inset 0px -90px 60px -40px rgba(0, 0, 0, 1);
  z-index: 94;
  transition: all 0.3s ease;
  opacity: 1;
}

.vpfr_hide_controls .vpfr_player_controls_shadow,
.vpfr_hide_controls .vpfr_player_controls {
  transform: translateY(81px);
}

.vpfr_container:hover .vpfr_player_controls_shadow,
.vpfr_container:hover .vpfr_player_controls {
  transform: translateY(0px);
}

.vpfr_video_poster_image {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  transition: opacity 0.6s ease;
  z-index: 93;
}

.vpfr_stopped_player .vpfr_video_poster_image {
  opacity: 1;
}

.vpfr_overlay_play_button {
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  background-color: transparent;
  z-index: 98;
  border: 0;
  border-radius: 100%;
  transform: translate(-50%, -55%);
  outline: none;
  transition: opacity 0.4s ease;
}

.vpfr_overlay_play_button svg {
  color: #fff;
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-left: -3px;
  margin-top: -0px;
  border-radius: 100%;
}

.vpfr_player_controls button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.vpfr_player_controls button svg {
  background-color: transparent;
  font-size: 28px;
  border-radius: 6px;
  padding: 4px;
  color: #fff;
  margin-top: 2px;
  transition: background-color 0.3s ease;
}

.vpfr_player_controls button:hover svg {
  background-color: #ff3e41;
}

.vpfr_video_captions {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: calc(100% - 80px);
  transform: translateY(calc(-10px * 11.5));
  z-index: 95;
  transition: transform 0.3s ease;
  pointer-events: none;
  padding: 10px 40px;
  text-align: center;
}

.vpfr_container:hover .vpfr_video_captions {
  transform: translateY(calc(-10px * 11.5));
}

.vpfr_hide_controls .vpfr_video_captions {
  transform: translateY(calc(-10px * 7.5));
}

.vpfr_video_captions .vpfr_caption_text {
  color: #fff;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.3em 0.6em;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  white-space: pre-wrap;
  font-size: 18px;
}

.vpfr_timers {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.vpfr_volume {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vpfr_player_item {
  margin: 0px 5px;
}

.vpfr_time_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  flex: 1;
}

.vpfr_time_progress .vpfr_timers:nth-child(1) {
  margin-right: 5px;
}

.vpfr_time_progress .vpfr_timers:nth-child(3) {
  margin-left: 5px;
}

.vpfr_time_progress_bar,
.vpfr_volume_progress_bar {
  width: 100%;
  padding: 5px 0px;
}

.vpfr_time_progress_fields,
.vpfr_volume_progress_fields {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vpfr_volume_progress_back,
.vpfr_volume_progress_fields {
  max-width: 140px;
  min-width: 0px;
  padding: 0px 5px;
}

.vpfr_time_progress_back,
.vpfr_volume_progress_back {
  position: absolute;
  height: 5px;
  width: calc(100% - 4px);
}

.vpfr_player_controls input[type='range'] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 26px;
  color: #ff3e41;
  display: block;
  height: calc((3px * 2) + 13px);
  margin: 0;
  min-width: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  outline: none;
  transition: 0.3s all ease;
  z-index: 100;
}

.vpfr_player_controls input[type='range']::-webkit-slider-runnable-track {
  height: 5px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(
    to right,
    currentColor var(--progress, 0),
    transparent var(--progress, 0)
  );
  position: relative;
  z-index: 101;
}

.vpfr_player_controls input[type='range']::-moz-range-progress {
  background: #ff3e41;
  height: 5px;
  border-radius: 4px;
}

.vpfr_player_controls input[type='range']::-moz-range-track {
  background: rgba(255, 255, 255, 0.5);
  height: 5px;
  border-radius: 6px;
}

.vpfr_player_controls input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  background: transparent;
  border-color: transparent;
  color: transparent;
}

.vpfr_player_controls input[type='range']::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  top: -3px;
  z-index: 102;
}

.vpfr_player_controls input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  border: 0;
  z-index: 102;
}

.vpfr_player_controls input[type='range']::-ms-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eee;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  z-index: 101;
  position: relative;
}
